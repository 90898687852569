import React from 'react'
import Container from '@components/Container'
import { ErrorPageWrapper } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading1, TextBody } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import Button from '@components/Button'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import IconArrowLeft from '@svgs/IconArrowLeft'

const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <Container>
        <Grid>
          <GridItem desk={12}>
            <Heading1>
              <AnimateSplitText>404 Error</AnimateSplitText>
            </Heading1>
            <Spacer size={24} />
            <TextBody>
              <AnimateSplitText>
                The page you're looking for was not found.
              </AnimateSplitText>
            </TextBody>
            <Spacer size={24} />
            <AnimateFadeIn>
              <Button type="internalLink" to={'/'} iconLeft={<IconArrowLeft />}>
                Back to Home
              </Button>
            </AnimateFadeIn>
            <Spacer size={24} />
          </GridItem>
        </Grid>
      </Container>
    </ErrorPageWrapper>
  )
}

ErrorPage.propTypes = {}

export default ErrorPage

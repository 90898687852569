import ErrorPage from '@components/ErrorPage'
import Footer from '@components/Footer'
import React from 'react'

const NotFoundPage = () => {
  return (
    <>
      <ErrorPage />
      <Footer />
    </>
  )
}

export default NotFoundPage
